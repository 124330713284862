import styled from 'styled-components'
import { Input } from 'antd'
import { defaultTheme } from 'theme'

export const InputSelectWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 30px;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        flex-flow: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding-right: 8px;
    }
`

export const CustomInput = styled(Input)`
    border-radius: 20px;
    height: 42px;
    background-color: #201e1e;
    border: 1px solid #332e2e !important;
    color: white;

    .ant-input {
        background-color: transparent;
        color: white;
    }
    .ant-input-clear-icon {
        color: white;
    }
`

export const SearchButtonWrapper = styled.div`
    margin-right: -5px;
`
