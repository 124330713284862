import { HomeCarousel } from 'components/Carousel'
import { BlockContainer, SectionContainer } from 'components/Container'
import AdsGPT from 'components/GPT/AdsGPT'
import {
    ClipScroller,
    LAZY_CARD_LOADS,
    ReplayScroller,
} from 'components/Scroller/VideoScroller'
import { siteSettings, useRouter, useUser } from 'hooks'
import { getSiteSettings } from 'hooks/settings'
import useMediaQuery from 'hooks/useMediaQuery'
import { visitHomeEvent } from 'lib/tracking/events/userId'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setClubInfo, setFanPassModalShown } from 'redux/actions/club'
import { getFilteredClips } from 'restful-client/clips'
import { getReplays } from 'restful-client/games'
import { GameDayView, LeaguesView } from 'sections/main/home'
import SearchFilter from 'sections/main/home/SearchFilter'
import { getPageSlugName } from 'utils/common-helper'
import InvitePlayerAdminModal from '../../components/v2/modal/invite-player-admin-modal'
import MobileSearch from './home/MobileSearch'

export const HomePage: React.FC<{ initialData: any }> = ({
    initialData,
}: any) => {
    const { search: initialSearch, sport: initialSport } = initialData
    const { path } = useRouter()
    const { user } = useUser()
    const dispatch = useDispatch()

    const [searchInput, setSearchInput] = useState<string>(initialSearch ?? '')
    const [sport, setSport] = useState<string>(initialSport ?? '')
    const isMobile = useMediaQuery(768)
    const showBillboard =
        !useMediaQuery(1100) && getSiteSettings().home_page.billboard_ad

    // track users when visiting home page
    useEffect(() => {
        visitHomeEvent({
            props: {
                message: 'Visit Home Page',
            },
        })
        dispatch(setClubInfo({}))
        dispatch(setFanPassModalShown(false))
    }, [dispatch, user])

    const getReplaysFunction = useCallback(
        (offset: any) =>
            getReplays(LAZY_CARD_LOADS, offset, searchInput, sport),
        [searchInput, sport],
    )

    const getClipsFunction = useCallback(
        (offset: number) =>
            getFilteredClips(searchInput, sport, LAZY_CARD_LOADS, offset),
        [searchInput, sport],
    )

    return (
        <>
            {user && <InvitePlayerAdminModal user={user} />}

            {isMobile && (
                <SectionContainer
                    display="block"
                    SectionView={MobileSearch}
                    sectionProps={{
                        searchInput,
                        setSearchInput,
                    }}
                />
            )}

            <SectionContainer
                display="block"
                SectionView={HomeCarousel}
                sectionProps={{
                    initialData,
                }}
                elementProps={{
                    smMargin: '12px 0 0 0',
                    margin: '16px 32px 32px 32px',
                }}
            />

            <SectionContainer
                display="block"
                SectionView={SearchFilter}
                sectionProps={{
                    searchInput,
                    sport,
                    setSearchInput,
                    setSport,
                }}
            />

            {siteSettings('home_page.leagues') && (
                <SectionContainer
                    display="block"
                    SectionView={LeaguesView}
                    sectionProps={{
                        type: 'Leagues',
                        initialData: { initialData },
                        searchInput,
                        sport,
                    }}
                />
            )}
            {siteSettings('home_page.gameday') && (
                <SectionContainer
                    display="block"
                    SectionView={GameDayView as any}
                    sectionProps={{
                        type: 'Live & Upcoming',
                        page: 'Home Page',
                        event: 'View Live Match',
                        initialData: { initialData },
                        searchInput,
                        sport,
                    }}
                />
            )}

            {isMobile && (
                <AdsGPT
                    slot={`mobile-${getPageSlugName(path)}-mrec`}
                    mobileSize={[300, 250]}
                    pos={1}
                />
            )}

            {siteSettings('home_page.replays') && (
                <BlockContainer>
                    <ReplayScroller
                        initialData={initialData}
                        type="Replay"
                        scrollerTitle="Replays"
                        page="Home Page"
                        event="View Replay"
                        getReplaysFunction={getReplaysFunction}
                        lazyLoadSize={LAZY_CARD_LOADS}
                    />
                </BlockContainer>
            )}

            {showBillboard && (
                <AdsGPT
                    slot={`desktop-${getPageSlugName(path)}-billboard`}
                    isBillboard={true}
                    pos={2}
                />
            )}

            {siteSettings('home_page.clips') && (
                <BlockContainer>
                    <ClipScroller
                        initialData={initialData}
                        scrollerTitle="Featured Clips"
                        page="Home Page"
                        event="View Clip"
                        getClipsFunction={getClipsFunction}
                        lazyCardLoads={LAZY_CARD_LOADS}
                    />
                </BlockContainer>
            )}

            {isMobile && (
                <AdsGPT
                    slot={`mobile-${getPageSlugName(path)}-footer-mrec`}
                    mobileSize={[300, 250]}
                    pos={3}
                />
            )}

            {!isMobile && (
                <AdsGPT
                    slot={`desktop-${getPageSlugName(path)}-footer`}
                    pos={3}
                />
            )}
        </>
    )
}
