import { useQuery } from '@apollo/client'
import { Modal } from 'antd'
import Play from 'assets/images/home/play.png'
import { Image } from 'components/Image'
import { query } from 'graphql/clips'
import dynamic from 'next/dynamic'
import React, { useState } from 'react'
import { AiFillCaretRight } from 'react-icons/ai'
import styled from 'styled-components'
import { defaultTheme } from 'theme'

const VideoPlayer = dynamic(() => import('components/Video/Bitmovin'), {
    ssr: false,
})

const SlideWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;

    align-items: center;

    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        min-height: 34vh;
        flex-direction: row;
    }
`

const SlideText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    font-size: 12px;

    @media screen and (min-width: ${defaultTheme.mediaSize.lg}px) {
        font-size: 18px;
        align-items: flex-start;
    }

    .headers {
        color: #ffffff;
        &.header {
            font-size: 1.2em;
            display: flex;
            align-items: center;
        }
        &.title {
            font-size: 2em;
        }
        &.sub-header {
            font-size: 1em;
            display: flex;
            align-items: center;
            font-weight: 500;
            @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
                font-size: 14px;
            }
        }
        @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
            text-align: center;
        }
    }
`

const ClipThumb = styled.div`
    width: 200px;
    height: 150px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 20px 2px #fff;
    margin: 20px;
    @media screen and (min-width: ${1400}px) {
        width: 400px;
        height: 250px;
    }
`

const CutomModal = styled(Modal)`
    .ant-modal-close {
        z-index: 1;
        color: red;
    }
    .ant-modal-body {
        padding: 5px;
        background-color: #1b1b25;
        box-shadow: 0px 0px 20px 2px #fff;
    }
`

interface Props {
    clipId: string
}

const ClipSlide: React.FC<Props> = ({ clipId }) => {
    const [show, setShow] = useState(false)

    const { loading, data } = useQuery(query.GET_CLIPS, {
        variables: { where: { playback_id: { _eq: clipId } } },
    })

    if (loading) {
        return <SlideWrapper />
    }

    const clipImage = `https://image.mux.com/${clipId}/thumbnail.jpg?width=500`

    return (
        <SlideWrapper>
            <SlideText>
                <h2 className="headers header">
                    <AiFillCaretRight color="red" />
                    <span>Clips of the Week</span>
                </h2>
                <h1 className="headers title">{data.clip_assets[0]?.name}</h1>
                <h2 className="headers sub-header">
                    <img
                        style={{ marginRight: 5, width: 40 }}
                        src={
                            data.clip_assets[0]?.clip_asset_user_clubs[0].club
                                .logo
                        }
                    />
                    <span>
                        {
                            data.clip_assets[0]?.clip_asset_user_clubs[0].club
                                .display_name
                        }
                    </span>
                </h2>
            </SlideText>

            <ClipThumb>
                <Image src={clipImage} layout="fill" oFit="cover" />
                <button
                    type="button"
                    onClick={() => {
                        setShow(true)
                    }}
                >
                    <Image src={Play} width={69} height={69} />
                </button>
            </ClipThumb>

            {show && (
                <CutomModal
                    footer={false}
                    width={`100vw`}
                    open={true}
                    onCancel={() => {
                        setShow(false)
                    }}
                >
                    <VideoPlayer
                        key={data.clip_assets[0].playback_id}
                        playback_id={data.clip_assets[0].playback_id}
                        league_name={
                            data?.clip_assets[0]?.match
                                ? data?.clip_assets[0]?.match?.league?.name
                                : data?.clip_assets[0]?.event_stream?.league
                                      ?.name
                        }
                        video_type={'Clip'}
                    />
                </CutomModal>
            )}
        </SlideWrapper>
    )
}

export default ClipSlide
