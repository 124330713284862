import { useEffect, useRef } from 'react'

export function useDebounce(func: (...args: any[]) => void, delay = 500) {
    const timer = useRef<ReturnType<typeof setTimeout>>()

    useEffect(() => {
        return () => {
            if (!timer.current) return
            // eslint-disable-next-line react-hooks/exhaustive-deps
            clearTimeout(timer.current)
        }
    }, [])

    const debouncedFunction = ((...args) => {
        const newTimer = setTimeout(() => {
            func(...args)
        }, delay)
        clearTimeout(timer.current)
        timer.current = newTimer
    }) as (...args: any[]) => void

    return debouncedFunction
}
