import { useMutation, useQuery } from '@apollo/client'
import { Button, Modal } from 'antd'
import { useRouter } from 'hooks'
import _ from 'lodash'
import { FC, useMemo } from 'react'
import { HomeQL, PLAYERQL, USERQL } from '../../../graphql/club'

const InvitePlayerAdminModal: FC<any> = (props) => {
    const [updateInvitedPlayerDetails] = useMutation(
        PLAYERQL.UPDATE_INVITED_PLAYER_DETAILS,
    )
    const [updateInvitedClubAdmin] = useMutation(
        HomeQL.UPDATE_INVITED_CLUB_ADMINS,
    )
    const [updateInvitedLeagueAdmin] = useMutation(
        HomeQL.UPDATE_INVITED_LEAGUE_ADMINS,
    )
    const [updateUser] = useMutation(USERQL.UPDATE_USER)

    const {
        data,
        loading,
        error,
        refetch: getClubInvite,
    } = useQuery(HomeQL.GET_CLUB_INVITE, {
        variables: {
            email_address: props.user?.email,
        },
    })
    const { move, asPath }: any = useRouter()
    const {
        data: league,
        loading: leagueLoading,
        error: leagueError,
        refetch: getLeagueInvite,
    } = useQuery(HomeQL.GET_LEAGUE_INVITE, {
        variables: {
            email_address: props.user?.email,
        },
    })

    const hasInvite = useMemo(() => {
        if ((loading && error) || (leagueLoading && leagueError)) return false

        if (league?.admin_details.length) {
            return true
        }

        if (data?.club_admin_details.length) {
            return true
        }

        if (data?.players_details.length) {
            return true
        }

        return false
    }, [loading, error, data, league, leagueLoading, leagueError])

    const getClubOrLeagueInviteDetails = useMemo(() => {
        if (!hasInvite) return {}

        if (league?.admin_details.length) {
            return league.admin_details[0]
        }

        if (data?.club_admin_details.length) {
            return data.club_admin_details[0]
        }

        if (data?.players_details.length) {
            return data.players_details[0]
        }
    }, [hasInvite, data, league])

    const getClubOrLeagueDetails = useMemo(() => {
        if (!hasInvite) return {}

        if (league?.admin_details.length) {
            return league.admin_details[0].league
        }

        if (data?.club_admin_details.length) {
            return data.club_admin_details[0].club
        }

        if (data?.players_details.length) {
            return data.players_details[0].club
        }
    }, [hasInvite, data, league])

    const inviteType = useMemo(() => {
        if (loading && error) return

        if (league?.admin_details.length) {
            return 'League Admin'
        }

        if (data?.club_admin_details.length) {
            return 'Club Admin'
        }

        if (data?.players_details.length) {
            return 'Player'
        }
    }, [loading, error, data, league, leagueLoading, leagueError])

    const acceptInvite = async () => {
        const variables = {
            where: {
                email_address: { _eq: props.user?.email },
                id: { _eq: getClubOrLeagueInviteDetails.id },
            },
            set: {
                user_id:
                    props?.user['https://hasura.io/jwt/claims'][
                        'x-hasura-user-id'
                    ],
                is_accepted: true,
                is_declined: false,
            },
        }

        try {
            if (
                !_.isEmpty(data?.club_admin_details) ||
                !_.isEmpty(data?.players_details)
            ) {
                if (inviteType === 'Player') {
                    await updateInvitedPlayerDetails({ variables })
                    await updateUser({
                        variables: {
                            where: { email: { _eq: props.user?.email } },
                            _set: {
                                photo: getClubOrLeagueInviteDetails.image,
                                first_name: getClubOrLeagueInviteDetails.name,
                                last_name:
                                    getClubOrLeagueInviteDetails.last_name,
                                user_role_id: 4,
                            },
                        },
                    })
                } else {
                    await updateInvitedClubAdmin({ variables })
                    await updateUser({
                        variables: {
                            where: { email: { _eq: props.user?.email } },
                            // _set: { user_role_id: 3 },
                        },
                    })
                }
                await getClubInvite({ email_address: props.user?.email })
                // await router.push(`/club/${getClubOrLeagueDetails?.slug}`)
                move(
                    `/api/auth/login?returnTo=/club/${getClubOrLeagueDetails?.slug}`,
                )
            } else {
                await updateInvitedLeagueAdmin({ variables })
                await updateUser({
                    variables: {
                        where: { email: { _eq: props.user?.email } },
                        // _set: { user_role_id: 7 },
                    },
                })
                await getLeagueInvite({ email_address: props.user?.email })
                // await router.push(`/league/${getClubOrLeagueDetails?.slug}`)
                move(
                    `/api/auth/login?returnTo=/league/${getClubOrLeagueDetails?.slug}`,
                )
            }
        } catch (e) {
            console.log(e)
        }
    }

    const declineInvite = async () => {
        const variables = {
            where: {
                email_address: { _eq: props.user?.email },
                id: { _eq: getClubOrLeagueInviteDetails.id },
            },
            set: {
                is_accepted: false,
                is_declined: true,
            },
        }

        try {
            if (inviteType === 'Player') {
                await updateInvitedPlayerDetails({ variables })
            } else {
                await updateInvitedClubAdmin({ variables })
            }
            await getClubInvite({ email_address: props.user?.email })

            if (inviteType === 'League Admin') {
                await updateInvitedLeagueAdmin({ variables })
            }
            await getLeagueInvite({ email_address: props.user?.email })
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <Modal
                className={'invite-modal'}
                footer={false}
                title={
                    <>
                        <div>
                            <span className={'text-xl'}>Hello </span>
                            <span
                                className={'font-bold text-xl text-[#ff4d4f]'}
                            >
                                {props.user?.nickname}
                            </span>
                        </div>
                    </>
                }
                open={hasInvite}
            >
                <p className={'text-xl'}>
                    You are invited to{' '}
                    <span className={'font-bold text-[#ff4d4f]'}>
                        {getClubOrLeagueDetails?.name}
                    </span>{' '}
                    as a{' '}
                    <span className={'text-[#ff4d4f] font-bold'}>
                        {inviteType}{' '}
                    </span>
                </p>
                <p className="text-xs">
                    *Once accepted please Log back in to refresh the
                    permissions.
                </p>
                <div className={'flex justify-end mt-10'}>
                    <Button
                        onClick={declineInvite}
                        className={'mr-3'}
                        size={'large'}
                        style={{
                            backgroundColor: '#262525',
                            borderColor: '#262525',
                            color: '#7B7575',
                            borderRadius: 8.5,
                        }}
                    >
                        Declined
                    </Button>
                    <Button
                        onClick={acceptInvite}
                        size={'large'}
                        style={{
                            backgroundColor: '#C72223',
                            color: 'white',
                            borderColor: '#C72223',
                            borderRadius: 8.5,
                        }}
                    >
                        Accept
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default InvitePlayerAdminModal
