import { getSession } from '@auth0/nextjs-auth0'
import { initializeApolloFromContext } from 'api/apollo'
import { Page } from 'components/Page'
import { INITIAL_CARD_LOADS } from 'components/Scroller/VideoScroller'
import _ from 'lodash'
import type { GetServerSideProps } from 'next'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setUserInfo } from 'redux/actions/user'
import { getAllSlides } from 'restful-client'
import { getFilteredClips } from 'restful-client/clips'
import { getReplays, searchEventsAndMatches } from 'restful-client/games'
import { getLeaguesBySearchInput } from 'restful-client/leagues'
import { LeagueElement, SlideTitleDetail } from 'restful-client/types'
import { ClipElement } from 'restful-client/types/clips'
import {
    EventElement,
    EventOrMatch,
    MatchElement,
} from 'restful-client/types/games'
import { HomePage } from 'sections/main/HomePage'
import { UserInfo } from 'types/common/user'
import { combineEventsAndMatches } from 'utils/common-helper'
import { sortLiveStream } from 'utils/helper-date'
import { fetchUserInfo } from 'utils/helper-user-info'

const Home: React.FC<{
    initialData: {
        league: LeagueElement[]
        matches: EventOrMatch[]
        replays: any[]
        clips: ClipElement[]
        slides: SlideTitleDetail[]
        replayMatches: MatchElement[]
        replayEvents: EventElement[]
    }
    userInfo: UserInfo | null
}> = (props) => {
    const { initialData, userInfo } = props
    const dispatch = useDispatch()

    useEffect(() => {
        userInfo && dispatch(setUserInfo(userInfo))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Page>
            <HomePage initialData={initialData} />
        </Page>
    )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    const { search, sport } = context.query
    const searchString = search ? search.toString() : ''
    const sportString = sport ? sport.toString() : ''

    const apolloClient = initializeApolloFromContext(context)

    const league = await getLeaguesBySearchInput(searchString, sportString)

    const matches = await searchEventsAndMatches(searchString, sportString)

    const replays = await getReplays(
        INITIAL_CARD_LOADS,
        0,
        searchString,
        sportString,
    )

    const clips = await getFilteredClips(
        searchString,
        sportString,
        INITIAL_CARD_LOADS,
        0,
    )

    const slides = await getAllSlides()

    const sess: any = getSession(context.req, context.res)
    const user = sess?.user
    const userInfo = user && (await fetchUserInfo(apolloClient, user))

    return {
        props: {
            initialData: {
                league: _.filter(league?.leagues, ['status', 'approved']),
                matches: matches ? sortLiveStream(matches) : [],
                replays: combineEventsAndMatches(
                    replays?.event_streams,
                    replays?.matches,
                ),
                clips: clips?.clip_asset_user_club ?? [],
                slides: slides?.slide_title_details ?? [],
                replayMatches: replays?.matches ?? [],
                replayEvents: replays?.event_streams ?? [],
                search: search ?? null,
                sport: sport ?? null,
            },
            initialApolloState: apolloClient.cache.extract(),
            userInfo: userInfo ?? null,
        },
    }
}

export default Home
