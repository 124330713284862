import { Button } from 'components/Button'
import useMediaQuery from 'hooks/useMediaQuery'
import Image from "next/legacy/image"
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { defaultTheme } from 'theme'

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 0 100px 0 100px;
    width: 100%;
    max-width: 1920px;
    align-items: center;
    height: 400px;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        min-height: 330px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.xs}px) {
        padding-top: 10px;
        min-height: 350px;
    }

    @media screen and (min-width: ${defaultTheme.mediaSize.lg}px) {
        flex-direction: row;
    }
`

const SlideWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    position: relative !important;
`

const SlideText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    font-size: 12px;
    margin-bottom: 30px;
    z-index: 0;

    @media screen and (min-width: ${defaultTheme.mediaSize.lg}px) {
        font-size: 18px;
        align-items: flex-start;
        margin-bottom: 0px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        margin-top: 10px;
    }

    .headers {
        color: #ffffff;
        &.header {
            font-size: 1em;
            font-weight: 500;
        }
        &.title {
            font-size: 2em;
        }
        &.sub-header {
            font-size: 0.9em;
            font-weight: 500;
            @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
                font-size: 14px;
            }
        }
        @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
            text-align: center;
        }
    }

    .text-wrapper {
        margin-bottom: 20px;
    }
`

const ImageContent = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;

    @media screen and (min-width: ${defaultTheme.mediaSize.lg}px) {
        align-self: flex-end;
    }

    /* img{ max-height: 200px; } */

    img {
        max-height: 400px;
        width: 400px;
        @media screen and (min-width: ${defaultTheme.mediaSize.lg}px) {
            width: auto;
        }
    }
`

const LinkButton = styled(Button)`
    margin: 0;
    padding: 20px;
`

interface Props {
    heading: string
    sub_heading: string
    image: string
    image_mobile: string
    image_mobile_landscape: string
    image_tablet: string
    cta_name?: string
    cta_link?: string
    background_image?: string
    currentWidth: number
}

const FeatureSlide: React.FC<Props> = (props) => {
    const [landScape, setLandScape] = useState(false)
    const [orientation, setOrientation] = useState<string | number>()

    useEffect(() => {
        const handleOrientationChange = () => {
            setOrientation(window.orientation)
        }

        if (typeof window !== 'undefined') {
            window.addEventListener(
                'orientationchange',
                handleOrientationChange,
            )
        }
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener(
                    'orientationchange',
                    handleOrientationChange,
                )
            }
        }
    }, [])

    useEffect(() => {
        if (window.orientation === 90) {
            setLandScape(true)
        } else {
            setLandScape(false)
        }
    }, [orientation])

    const isMobilePortrait = useMediaQuery(480)
    const isTablet = useMediaQuery(768)
    const isMobileLandscape = useMediaQuery(926)

    const {
        heading,
        sub_heading,
        image,
        image_mobile,
        image_mobile_landscape,
        image_tablet,
        cta_name,
        cta_link,
        background_image,
        currentWidth,
    } = props

    const path =
        image_mobile_landscape && landScape
            ? isMobileLandscape
                ? image_mobile_landscape
                : background_image
            : image_mobile && isMobilePortrait
              ? image_mobile
              : image_tablet && isTablet
                ? image_tablet
                : background_image

    return (
        <SlideWrapper>
            <Image src={`${path}`} layout="fill" quality={100} alt="" />

            <ContentWrapper
                style={{
                    height: currentWidth > 800 ? `${currentWidth / 4.8}px` : ``,
                }}
            >
                <SlideText>
                    <div className="text-wrapper">
                        <h1 className="headers title">{heading}</h1>
                        <h2 className="headers sub-header">{sub_heading} </h2>
                    </div>

                    {cta_link && cta_name && (
                        (<Link href={cta_link}>

                            <LinkButton>{cta_name}</LinkButton>

                        </Link>)
                    )}
                </SlideText>

                {image && (
                    <ImageContent>
                        <img src={image} />
                    </ImageContent>
                )}
            </ContentWrapper>
        </SlideWrapper>
    );
}

export default FeatureSlide
