import { Button } from 'components/Button'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from 'theme'

const SlideWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 10px;

    @media screen and (min-width: ${defaultTheme.mediaSize.lg}px) {
        min-height: 34vh;
        flex-direction: row;
        padding-top: 0px;
    }
`

const SlideText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    font-size: 12px;

    @media screen and (min-width: ${defaultTheme.mediaSize.lg}px) {
        font-size: 18px;
        align-items: flex-start;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        margin-top: 10px;
    }

    .headers {
        color: #ffffff;
        &.header {
            font-size: 1em;
            font-weight: 500;
        }
        &.title {
            font-size: 2em;
        }
        &.sub-header {
            font-size: 0.8em;
            font-weight: 500;
            @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
                font-size: 14px;
            }
        }
        @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
            text-align: center;
        }
    }

    .text-wrapper {
        /* margin-bottom: 20px; */
    }
`

const ImageContent = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-height: 400px;
    }

    @media screen and (min-width: ${defaultTheme.mediaSize.lg}px) {
        width: auto;
        height: auto;
        img {
            max-height: initial;
        }
    }
`

interface Props {
    heading: string
    sub_heading: string
    image: string
    cta_name?: string
    cta_link?: string
}

const EventSlide: React.FC<Props> = (props) => {
    const { heading, sub_heading, image, cta_name, cta_link } = props

    return (
        <SlideWrapper>
            <SlideText>
                <div className="text-wrapper">
                    <h2 className="headers header"> Upcoming Events </h2>
                    <h1 className="headers title">{heading}</h1>
                    <h2 className="headers sub-header">{sub_heading} </h2>
                </div>

                {cta_name && (
                    <Link href={cta_link ?? '#'} legacyBehavior>
                        <Button style={{ margin: 0 }}>{cta_name}</Button>
                    </Link>
                )}
            </SlideText>

            {image && (
                <ImageContent>
                    <img style={{ maxWidth: '40vw' }} src={image} />
                </ImageContent>
            )}
        </SlideWrapper>
    );
}

export default EventSlide
