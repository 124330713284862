import { useQuery } from '@apollo/client'
import { Button } from 'components/Button'
import { Image } from 'components/Image'
import { Text } from 'components/Text'
import { query } from 'graphql/stream'
import useMatchUrl from 'hooks/matchUrl'
import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from 'theme'
import { MatchGameClubs } from 'types/components/GameCard'
import { datetimeToLocal } from 'utils/helper-date'

const SlideWrapper = styled.div`
    display: flex !important;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    /* min-height: 30vh; */
    /* margin-bottom: 30px; */
    padding-top: 10px;
`
const SlideText = styled.div`
    display: flex !important;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        text-align: center;
    }
`
const VsText = styled.span`
    color: #f93737;
    font-weight: bold;
    font-size: 20px;
    margin: 0px 10px;
    @media screen and (min-width: ${defaultTheme.mediaSize.lg}px) {
        margin: 0px 40px;
    }
`
const ClubNameText = styled.h1`
    color: #ffffff;
    font-size: 14px;
    @media screen and (min-width: ${defaultTheme.mediaSize.lg}px) {
        font-size: 24px;
    }
`

const ClubInfo = styled.div`
    text-align: center;
`

export interface MatchSlideProps {
    matchId: number
    currentWidth: number
}

/** CTA Button */
const CTA = ({ match }: any) => {
    const { away_team, home_team, id, league } = match
    const mode = match?.stream_info?.status === 'completed' ? 'Replay' : 'Match'
    const matchClubs: MatchGameClubs = {
        match_id: id,
        away_slug: away_team.club.slug,
        away_club_id: away_team.club.id,
        home_slug: home_team.club.slug,
        home_club_id: away_team.club.id,
        league_slug: league.slug,
    }
    const ctaLink = useMatchUrl(mode, { matchClubs })
    return (
        <Link href={ctaLink} legacyBehavior>
            <Button>View Match</Button>
        </Link>
    );
}

const MatchSlide: React.FC<MatchSlideProps> = ({ matchId, currentWidth }) => {
    const { loading, data } = useQuery(query.GET_MATCH, {
        variables: { where: { id: { _eq: matchId } } },
    })

    if (loading) {
        return <SlideWrapper />
    }

    const { away_team, home_team } = data.matches[0]

    const isEvent = away_team.club.id === home_team.club.id

    const logoWebDimension =
        currentWidth > 1750
            ? 200
            : currentWidth > 1500
              ? 150
              : currentWidth > 1200
                ? 100
                : 80
    const logoUseDimension = currentWidth > 770 ? logoWebDimension : 200

    return (
        <SlideWrapper className="slide-wrapper">
            <SlideText>
                <ClubInfo>
                    <Image
                        src={home_team.club.logo}
                        width={logoUseDimension}
                        height={logoUseDimension}
                    />
                    <ClubNameText>{home_team.club.name}</ClubNameText>
                </ClubInfo>

                {!isEvent && <VsText>VS</VsText>}

                {!isEvent && (
                    <ClubInfo>
                        <Image
                            src={away_team.club.logo}
                            width={logoUseDimension}
                            height={logoUseDimension}
                        />
                        <ClubNameText>{away_team.club.name}</ClubNameText>
                    </ClubInfo>
                )}
            </SlideText>
            <div style={{ marginBottom: 20 }}>
                <Text fSize={1} fColor="white" suppressHydrationWarning={true}>
                    {datetimeToLocal(data.matches[0].start_datetime, {
                        hideTime: true,
                    })}
                </Text>
            </div>

            <CTA match={data.matches[0]} />
        </SlideWrapper>
    )
}

export default MatchSlide
