import dynamic from 'next/dynamic'
import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from 'theme'
import renderHTML from 'react-render-html'

const SlideWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content:  space-evenly;
    /* min-height: 30vh; */
    align-items: center;
    /* margin-bottom: 30px; */
    padding-top: 10px;
    @media screen and (min-width: ${defaultTheme.mediaSize.sm}px) {
        min-height: 34vh;
        flex-direction: row;
        padding-top: 0px;
    }
`

interface Props {
    html: string
}

const CustomSlide: React.FC<Props> = ({ html }) => {
    return <SlideWrapper>{renderHTML(html)}</SlideWrapper>
}

export default CustomSlide
