import { BaseSyntheticEvent, SetStateAction, useEffect, useState } from 'react'
import {
    CustomClearButton,
    CustomInput,
    CustomSelect,
    InputSelectWrapper,
    SearchButtonWrapper,
} from './index.style'
//antd
import { SearchArrow } from 'assets/icon/searchFilter'
import { Button } from 'components/Button'
import { useRouter } from 'hooks'
import { updateUrl } from 'hooks/updateUrl'
import { useDebounce } from 'hooks/useDebounce'
import { searchInputEvent } from 'lib/tracking/events'
import { sportsFilterEvent } from 'lib/tracking/events/filter'
import { getAllSports } from 'restful-client/sports'
import { Sports } from 'restful-client/types/sports'

const SearchFilter = (props: any) => {
    const { searchInput, sport, setSearchInput, setSport } = props

    const [sports, setSports] = useState<Sports | null>(sport)
    const [localSearchInput, setLocalSearchInput] = useState<string>(
        searchInput ?? '',
    )

    const {
        router,
        query: { search: urlSearch, sport: urlSport },
    } = useRouter()

    // When the filter state change, update the url and log
    useEffect(() => {
        updateUrl(router, searchInput, sport)

        //push search input to gtm/datadog
        searchInputEvent({
            input: {
                search: searchInput,
                sport: sport,
            },
            gtm: true,
            datadog: true,
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput, sport])

    // When the url changes:
    //     If (url !== filter state):
    //         This change is the result of a navigation, update the filter state to match
    //     Else:
    //         This change is the result of the above useEffect, don't update the filter state
    useEffect(() => {
        if (urlSearch !== searchInput) {
            if (urlSearch) {
                setSearchInput(urlSearch.toString())
                setLocalSearchInput(urlSearch.toString())
            } else {
                setSearchInput('')
                setLocalSearchInput('')
            }
        }
        if (urlSport !== sport) {
            if (urlSport) {
                setSport(urlSport.toString())
            } else {
                setSport('')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlSearch, urlSport])

    useEffect(() => {
        const fetchData = async () => {
            const data = await getAllSports()
            data && setSports(data)
        }
        fetchData()
    }, [])

    const onPressEnter = (e: BaseSyntheticEvent) => {
        setSearchInput(localSearchInput)
    }

    const onChange = (e: { target: { value: SetStateAction<string> } }) => {
        setLocalSearchInput(e.target.value)
        debouncedSetInput(e.target.value)
    }

    const debouncedSetInput = useDebounce((value: string) => {
        setSearchInput(value.toString())
    })

    const handleSports = (value: undefined | string) => {
        value === undefined ? setSport('') : setSport(`${value}`)

        //push sports filter data to gtm/datadog
        const searchAndSport = {
            search: searchInput,
            sport: value,
        }

        sportsFilterEvent({
            filter: searchAndSport,
            gtm: true,
            datadog: true,
        })
    }

    const clearSportFilter = () => {
        setSport('')

        const searchAndSport = {
            search: searchInput,
            sport: 'Clear Sport',
        }
        sportsFilterEvent({
            filter: searchAndSport,
            gtm: true,
            datadog: true,
        })
    }

    return (
        <InputSelectWrapper>
            <CustomInput
                size={'large'}
                placeholder="Search for content by Name, League, Club or Sport…"
                suffix={
                    <SearchButtonWrapper>
                        <Button bSize="iconsize" onClick={onPressEnter}>
                            <SearchArrow />
                        </Button>
                    </SearchButtonWrapper>
                }
                onChange={onChange}
                onPressEnter={onPressEnter}
                value={localSearchInput || ''}
            />

            <CustomSelect
                placeholder={'Filter by Sport'}
                size={'large'}
                onChange={handleSports as any}
                options={
                    sports
                        ? sports?.sports?.map((value) => ({
                              label: value.name,
                              value: value.name,
                          }))
                        : []
                }
                value={sport || undefined}
            />

            {sport && (
                <CustomClearButton onClick={clearSportFilter}>
                    Clear Filter
                </CustomClearButton>
            )}
        </InputSelectWrapper>
    )
}
export default SearchFilter
