import React, { useEffect, useState } from 'react'

import {
    CustomInput,
    InputSelectWrapper,
    SearchButtonWrapper,
} from './index.style'
//antd
import { SearchOutlined } from '@ant-design/icons'
import { SearchArrow } from 'assets/icon/searchFilter'
import { Button } from 'components/Button'
import { useRouter } from 'hooks'
import { useDebounce } from 'hooks/useDebounce'

const MobileSearch = (props: any) => {
    const { searchInput, setSearchInput } = props
    const [localSearchInput, setLocalSearchInput] = useState<string>(
        searchInput ?? '',
    )

    const {
        query: { search: urlSearch },
    } = useRouter()

    // This is a reduced version of the logic in the desktop SearchFilter, as even in mobile layout
    // that component is still executing it's logic and is controlling the search/url interactions.
    // This component only needs to set its own local state.
    useEffect(() => {
        if (urlSearch !== searchInput) {
            if (urlSearch) {
                setLocalSearchInput(urlSearch.toString())
            } else {
                setLocalSearchInput('')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlSearch])

    const onPressEnter = () => {
        setSearchInput(localSearchInput)
    }

    const onChange = (e: {
        target: { value: React.SetStateAction<string> }
    }) => {
        setLocalSearchInput(e.target.value)
        debouncedSetInput(e.target.value)
    }

    const debouncedSetInput = useDebounce((value: string) => {
        setSearchInput(value.toString())
    })

    return (
        <InputSelectWrapper>
            <CustomInput
                size={'large'}
                placeholder="Search for content by Name, League, Club or Sport…"
                suffix={
                    localSearchInput !== '' ? (
                        <SearchButtonWrapper>
                            <Button bSize="iconsize" onClick={onPressEnter}>
                                <SearchArrow />
                            </Button>
                        </SearchButtonWrapper>
                    ) : (
                        <SearchOutlined />
                    )
                }
                onChange={onChange}
                onPressEnter={onPressEnter}
                value={localSearchInput}
            />
        </InputSelectWrapper>
    )
}
export default MobileSearch
