import { CardProps } from 'components/Card/ItemCard/ItemCard'
import { CardScroller } from 'components/Scroller/CardScroller'
import { leagueEvent } from 'lib/tracking/events'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getLeaguesBySearchInput } from 'restful-client/leagues'
import { LeagueElement, Leagues } from 'restful-client/types/leagues'

const LeaguesView: React.FC = (props: any) => {
    const { setLeagueInfo, initialData, searchInput, sport } = props

    const ssrData = initialData?.initialData?.league
    const [data, setData] = useState<LeagueElement[]>(ssrData)
    const [error, setError] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data: Leagues | undefined = await getLeaguesBySearchInput(
                    searchInput,
                    sport,
                )

                data && setData(_.filter(data?.leagues, ['status', 'approved']))
            } catch (error) {
                setError(true)
            }
        }
        fetchData()
    }, [searchInput, sport])

    if (error) return <div>Error!</div>

    const handleClick = (card: CardProps) => {
        leagueEvent({
            props: {
                leagueName: card.title,
                message: 'Select League',
            },
        })
    }

    return (
        <CardScroller
            title="Leagues"
            data={data.map((l) => {
                return {
                    title: l.name,
                    image: l.logo,
                    slug: l.slug,
                    href: `/league/${l.slug}`,
                }
            })}
            type="League"
            handleClick={handleClick}
        />
    )
}

export default LeaguesView
