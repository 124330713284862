import React from 'react'
// type
import { IconProps } from 'types/components/Icon'

// -----------------------------------------------
const Icon: React.FC<IconProps> = ({
    iColor = '#FA3737',
    iSize = { x: 86, y: 57 },
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
        >
            <path
                fill="currentColor"
                d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12s4.48 10 10 10s10-4.48 10-10zm-10 2.79V13H9c-.55 0-1-.45-1-1s.45-1 1-1h3V9.21c0-.45.54-.67.85-.35l2.79 2.79c.2.2.2.51 0 .71l-2.79 2.79a.5.5 0 0 1-.85-.36z"
            />
        </svg>
    )
}
export default Icon
