export function updateUrl(
    router: any,
    searchInput: string | null,
    filteredSport: string | null,
) {
    const baseUrl = process.env.NEXT_PUBLIC_BASE_URL

    const newUrl = new URL('/', baseUrl)

    if (searchInput !== '') {
        newUrl.searchParams.set('search', searchInput as string)
        if (filteredSport === '' || filteredSport === null) {
            router.push(newUrl.href, undefined, {
                scroll: false,
            })
        } else {
            newUrl.searchParams.set('sport', filteredSport as string)

            router.push(newUrl.href, undefined, { scroll: false })
        }
    } else {
        if (filteredSport === '' || filteredSport === null) {
            router.push(newUrl.origin, undefined, { scroll: false })
        } else {
            newUrl.searchParams.set('sport', filteredSport as string)

            router.push(newUrl.href, undefined, {
                scroll: false,
            })
        }
    }
}
