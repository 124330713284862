
import { get } from './helpers/request'
import { Sports } from './types/sports'
const baseUrl = process.env.NEXT_PUBLIC_RESTFUL_BASE_URL

export async function getAllSports() {
    try {
        const response = await get<Sports>(`${baseUrl}/sports`)
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}