import styled from 'styled-components'
import { Button, Input, Select } from 'antd'
import { defaultTheme } from 'theme'

export const InputSelectWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        flex-flow: row;
        width: 100%;
        gap: 10px;
        justify-content: start;
        align-items: center;
    }
`

export const CustomInput = styled(Input)`
    border-radius: 20px;
    height: 52px;
    background-color: #201e1e;
    border: 1px solid #332e2e !important;
    width: 35%;
    color: white;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        display: none;
    }

    .ant-input {
        background-color: transparent;
        color: white;
    }
    .ant-input-clear-icon {
        color: white;
    }
`
export const CustomSelect = styled(Select)`
    width: 15%;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        width: 50%;
    }

    .ant-select-selector {
        height: 52px !important;
        align-items: center !important;
        border-radius: 20px !important;
        background-color: #201e1e !important;
        border: 1px solid #332e2e !important;
        color: white !important;

        @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
            height: 42px !important;
        }
    }
    .ant-select-arrow {
        color: #332e2e;
    }
    .ant-select-clear {
        background-color: #201e1e;
        color: white;
        top: 43%;
    }
`

export const CustomClearButton = styled(Button)`
    border-radius: 20px;
    height: 52px;
    background-color: #201e1e;
    border: 1px solid #332e2e !important;
    color: white;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        height: 42px;
    }
`

export const SearchButtonWrapper = styled.div`
    margin-right: -5px;
`
