import { useUser } from '@auth0/nextjs-auth0'
import { Carousel } from 'antd'
import React, { useEffect, useState } from 'react'
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai'
import styled from 'styled-components'
import { defaultTheme } from 'theme'
import {
    ClipSlide,
    CustomSlide,
    EventSlide,
    FeatureSlide,
    MatchSlide,
} from './slides'

interface HomeCarouselInterface {
    initialData?: any
}

const CarouselWrapper = styled(Carousel)`
    height: 400px;
    border-radius: 24px;
    overflow: hidden;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        min-height: 330px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.xs}px) {
        min-height: 350px;
        border-radius: 18px;
    }

    .slick-dots-bottom {
        bottom: 10px;
        @media screen and (min-width: ${defaultTheme.mediaSize.lg}px) {
            bottom: 25px;
        }
    }

    .slick-dots {
        margin-left: auto;
        margin-right: auto;

        li {
            button {
                padding: 3px 0px;
                &::before {
                    display: none;
                }
            }
            &.slick-active {
                button {
                    background: red;
                }
            }
        }
    }
`
const NextArrow = (props: any) => {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <AiFillRightCircle size={40} fill={'white'} />
        </div>
    )
}

const PrevArrow = (props: any) => {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <AiFillLeftCircle size={40} fill={'white'} />
        </div>
    )
}

const HomeCarousel: React.FC<HomeCarouselInterface> = (props: any) => {
    const { initialData } = props
    const ssrData = initialData?.slides
    const [currentWidth, setCurrentWidth] = useState(0)
    const { user } = useUser()

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setCurrentWidth(window.innerWidth)
            const handleResize = () => {
                setCurrentWidth(window.innerWidth)
            }
            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
        }
    }, [])

    const sortedData = user
        ? ssrData.filter((slide: any) => slide.user_flag === false)
        : ssrData

    return (
        <div style={{ position: 'relative' }}>
            <CarouselWrapper
                style={{
                    height: currentWidth > 800 ? `${currentWidth / 4.8}px` : ``,
                }}
                autoplaySpeed={6000}
                autoplay={true}
                nextArrow={<NextArrow />}
                prevArrow={<PrevArrow />}
            >
                {ssrData &&
                    sortedData?.map((slide: any) => {
                        if (slide.type === 'custom')
                            return (
                                <CustomSlide
                                    key={`home-carousel-item-${slide.id}`}
                                    html={slide.custom_html}
                                />
                            )

                        if (slide.type === 'feature')
                            return (
                                <FeatureSlide
                                    key={`home-carousel-item-${slide.id}`}
                                    {...slide}
                                    currentWidth={currentWidth}
                                />
                            )

                        if (slide.type === 'event')
                            return (
                                <EventSlide
                                    key={`home-carousel-item-${slide.id}`}
                                    {...slide}
                                />
                            )

                        if (slide.type === 'clip')
                            return (
                                <ClipSlide
                                    key={`home-carousel-item-${slide.id}`}
                                    clipId={slide.clip_id}
                                />
                            )

                        if (slide.type === 'match')
                            return (
                                <MatchSlide
                                    key={`home-carousel-item-${slide.id}`}
                                    matchId={slide.match_id}
                                    currentWidth={currentWidth}
                                />
                            )
                    })}
            </CarouselWrapper>
        </div>
    )
}

export default HomeCarousel
